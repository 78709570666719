<!-- 首页  -->
<template>
  <div>
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户中心</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span><i class="el-icon-lock" style="color:#67C23A"></i> 用户信息</span>
          </div>
          <div class="text item">
            <el-form label-width="100px">
              <el-form-item label="登录名">{{userInfo.loginName}}</el-form-item>
              <el-form-item label="用户名">{{userInfo.userName}}</el-form-item>
              <el-form-item label="用户角色">{{userInfo.roleName}}</el-form-item>
              <el-form-item label="发文统计所属">{{userInfo.depName}}</el-form-item>

            </el-form>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span><i class="el-icon-cpu" style="color:#67C23A"></i> 密码修改 </span>
          </div>
          <div class="text item">
            <el-form ref="passDataForm" :model="passData" :rules="rules" label-width="100px">
              <el-form-item label="旧密码" prop="oldPass"><el-input v-model="passData.oldPass" prefix-icon="el-icon-lock" placeholder="请输入旧密码"
                  type="password"></el-input></el-form-item>
              <el-form-item label="新密码" prop="newPass"><el-input v-model="passData.newPass" prefix-icon="el-icon-lock" placeholder="请输入新密码"
                  type="password"></el-input></el-form-item>
              <el-form-item label="重复" prop="rePass"><el-input v-model="passData.rePass" prefix-icon="el-icon-lock" placeholder="请再一次输入新密码"
                  type="password"></el-input></el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('passDataForm')">修 改</el-button>
                <el-button @click="resetForm('passDataForm')">重 置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import { doModify } from '@/api/wbkj/UserInfo';
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      userInfo: {},
      passData: {
        oldPass: '',
        newPass: '',
        rePass: ''
      },
      rules: {
        oldPass: [{
          required: true,
          message: '请输旧密码',
          trigger: 'change'
        }],
        newPass: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        },
        {
          min: 6,
          max: 12,
          message: '长度在 6 到 12 个字符',
          trigger: 'blur'
        }
        ],
        rePass: [{
          required: true,
          message: '请再次输入密码',
          trigger: 'blur'
        },
        {
          min: 6,
          max: 12,
          message: '长度在 6 到 12 个字符',
          trigger: 'blur'
        }
        ]
      }

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    /*-------------------------------------------------特殊事件 -----------------------------------------------*/
    /*特殊事件-出错跟踪 */
    notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); },
    notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); },
    notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },
    notifyError (error) { this.$notify.error({ title: '错误', message: error }); },

    submitForm (formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          doModify(this.passData).then(response => {
            if (response.data.success) {
              this.notifySuccess(response.data.msg);
            }
            else {
              this.notifyWarning(response.data.msg);
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

    this.userInfo = this.$store.getters.getLoginUser;
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
