<!--  -->
<template>
  <el-row>
    <el-col :span="21">
      <el-menu mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" router>
        <el-menu-item @click="hidenShowLeftMenu" class="menuItemByShowOrHiden"><i :class="`${iconClass}`"></i>
        </el-menu-item>
        <el-submenu v-for="node in menuData" :key="node.id" :index="node.panelName+node.id">
          <template slot="title" v-if="node.bodyItems"><i :class="node.imageUrl"></i>{{node.panelName}}</template>
          <el-menu-item v-for="cusPanel in node.bodyItems" :key="cusPanel.id" :index="cusPanel.panelEnName">{{cusPanel.panelName}}</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-col>
    <el-col :span="3">
      <el-menu mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" router>
        <el-submenu index="5">
          <template slot="title"><i class="el-icon-s-custom" style="color:#409eff">&nbsp;</i>{{this.$store.getters.getLoginUser.loginName}}</template>
          <el-menu-item index="userInfo">用户中心</el-menu-item>
          <el-menu-item @click="loginOut">退出系统</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-col>
  </el-row>
</template>
<style lang="less" scoped>
.menuItemByShowOrHiden {
  padding: 0px;
}
</style>
<script>
import { getTopMenus } from '@/api/wbkj/MainMenu'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
export default {
  data () {
    return {
      iconHiden: false,
      iconClass: "el-icon-caret-left",
      menuData: []
    };
  },
  //方法集合
  methods: {
    hidenShowLeftMenu () {
      this.$data.iconStatus = !this.$data.iconStatus;
      if (this.$data.iconStatus) {
        this.$data.iconClass = "el-icon-caret-right";
      }
      else {
        this.$data.iconClass = "el-icon-caret-left";
      }
      this.$store.commit('cllapseMenu');
    },
    loginOut () {
      this.$store.commit("delToken");
      //that.$store.commit("setUserInfo", response.data.account);
      //sessionStorage.setItem("loginUser",)
      setTimeout(() => {
        // 此时要判断/login后面的参数redirect，若无参数，进入主页；
        this.$router.push("/login");
        // 若有参数则参数为未有权限的那个路由，跳转到那个路由
        // this.$router.push(***); -- 具体要自己在这实现
      }, 1000);
    }
  },
  mounted () {
    getTopMenus().then(response => {
      if (response.data.success) {
        this.menuData = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
  }
}
</script>