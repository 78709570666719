<template>
  <el-container>
    <left-menu></left-menu>
    <el-container>
      <el-header>
        <top-menu></top-menu>
      </el-header>
      <el-main id="mainInfo">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<style lang="less" scoped>
.el-header {
  padding: 0;
}
</style>
<script>
import LeftMenu from '../components/wbkj/LeftMenu'
import TopMenu from '../components/wbkj/TopMenu'
export default {
  data () {
    return {}
  },
  components: { LeftMenu, TopMenu }
}
</script >