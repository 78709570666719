<!-- 首页  -->
<template>
  <div>
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span><i class="el-icon-data-analysis" style="color:#67C23A"></i> 统计信息
            <el-select placeholder="请选择年份" v-model="selectYear" style="width:150px;margin-right:10px;margin-left:10px;" @change="getCountData">
              <el-option v-for="item in years" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select placeholder="请选择统计分组" v-model="selectGroup" @change="getCountData">
              <el-option v-for="item in groups" :key="item.id" :label="item.viewName" :value="item.id">
              </el-option>
            </el-select>

          </span>
        </div>
        <div class="text item">
          <div style="width: auto;height: 300px" id="main">
          </div>
        </div>
      </el-card>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span><i class="el-icon-lock" style="color:#67C23A"></i> 授权信息</span>
          </div>
          <div class="text item">
            <el-form label-width="80px" size="mini">
              <el-form-item label="授权机构">{{cerInfo.CN}}</el-form-item>
              <el-form-item label="联系电话">{{cerInfo.TelephoneNumber}}</el-form-item>
              <el-form-item label="E-mail">{{cerInfo.E}}</el-form-item>
              <el-form-item label="所在地">{{cerInfo.ST}}-{{cerInfo.L}}</el-form-item>
              <el-form-item label="有效期" v-if="cerInfo.day>30">{{cerInfo.endDate}} 还有
                <el-tag size="small" v-if="cerInfo.day>30">{{cerInfo.day}}</el-tag>
                <el-tag size="small" type="danger" v-else>{{cerInfo.day}}</el-tag> 天
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span><i class="el-icon-key" style="color:#67C23A"></i> 证书信息
              <i class="el-icon-check" v-if="cerInfo.day>1" style="color:#67C23A">正常</i>
              <i class="el-icon-close" v-else style="color:#F56C6C">过期</i>
            </span>
            <el-upload ref="cerUpload" action="" :http-request="cerUploadSubmit" :before-upload="cerSubmitBefore" :on-error="notifyError"
              :show-file-list="false" style="float: right; padding: 0;margin: 0px;">
              <el-button type="text" style="padding: 0 3px;">更换</el-button>
            </el-upload>
          </div>
          <div class="text item">
            <el-input type="textarea" style="margin-top:10px;border:0px;" resize="none" rows="10" v-model="cerInfo.key" readonly=""></el-input>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span><i class="el-icon-cpu" style="color:#67C23A"></i> 系统信息 </span>
          </div>
          <div class="text item">
            <el-form label-width="80px" size="mini">
              <el-form-item label="操作系统">{{sysInfo.osName}} {{sysInfo.osArch }} {{sysInfo.osVersion}}</el-form-item>
              <el-form-item label="虚拟机">{{sysInfo.jvmName}} {{sysInfo.javaVersion}}</el-form-item>
              <el-form-item label="Java版本">{{sysInfo.javaVersion}} ({{sysInfo.jvmVersion}})</el-form-item>
              <el-form-item label="平台路径">JAVA_HOME:{{sysInfo.javaHome}}</el-form-item>
              <el-form-item label="已用内存">
                <el-progress :text-inside="true" :stroke-width="20" :percentage="sysInfo.perNum"></el-progress></el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import { getCer, getDiskInfo, getSys, getGroups, getGroupCount, postCerFile } from '@/api/wbkj/Home';
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      selectYear: '2023',
      selectGroup: 1,
      years: [
        {
          value: '2021',
          label: '2021年'
        },
        {
          value: '2022',
          label: '2022年'
        }, {
          value: '2023',
          label: '2023年'
        }, {
          value: '2024',
          label: '2024年'
        }, {
          value: '2025',
          label: '2025年'
        }, {
          value: '2026',
          label: '2026年'
        }, {
          value: '2027',
          label: '2027年'
        }],
      cerInfo: {},
      sysInfo: {},
      diskInfo: {},
      groups: [], //统计的部门
      groupCount: [],//统计的数据
      countOption: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '上传总数',
          data: [],
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(220, 220, 220, 0.8)'
          },
          label: {
            show: true, //开启显示
            position: 'top', //在上方显示
            textStyle: { //数值样式
              color: '#000',
            }
          }
        },
        {
          name: '发布数',
          data: [],
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(220, 220, 220, 0.8)'
          },
          label: {
            show: true, //开启显示
            position: 'top', //在上方显示
            textStyle: { //数值样式
              color: '#000',
            }
          }
        },
        {
          name: '总点击数',
          data: [],
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(220, 220, 220, 0.8)'
          },
          label: {
            show: true, //开启显示
            position: 'top', //在上方显示
            textStyle: { //数值样式
              color: '#000',
            }
          }
        }]
      }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    /*-------------------------------------------------特殊事件 -----------------------------------------------*/
    /*特殊事件-出错跟踪 */
    notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); },
    notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); },
    notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },
    notifyError (error) { this.$notify.error({ title: '错误', message: error }); },
    /*上传cer文件类型判断,默认doc,docx,pdf是都可以上传*/
    cerSubmitBefore (file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'))
      if ('.cer'.indexOf(fileType) == -1) {  //允许的文件类型请自己修改,但请注意,是带.的
        this.notifyWarning('上传失败,请上传.cer文件')
        return false;
      }
      else {
        return true;
      }
    },
    cerUploadSubmit (param) {
      const cerFileData = new FormData()
      cerFileData.append('file', param.file);
      postCerFile(cerFileData).then(response => {
        if (response.data.success) {
          this.cerInfo = response.data.data;
          //this.formData.cer = jsonData.serverPath;
          /*this.formData.imageUrl = jsonData.fileImages;
          this.formData.title = jsonData.fileName;
          this.formData.note = jsonData.fileNote;
          this.editorHtmlData = jsonData.fileContent;*/
          param.onSuccess();  // 上传成功的图片会显示绿色的对勾
        }
        else {
          param.onError(response.data.msg);//会去调用出错的方法
        }
      }).catch(error => {
        param.onError(error); //会去调用出错的解决办法
      })
    },





    /*特殊事件-下载文件 */
    echartsInit () {
      //柱形图
      //因为初始化echarts 的时候，需要指定的容器 id='main'
      this.$echarts.init(document.getElementById('main')).setOption(this.countOption);
    },
    getCountData () {
      getGroupCount({ "year": this.selectYear, group: this.selectGroup }).then(response => {
        if (response.data.success) {
          this.countOption.title.text = this.selectYear + '年';
          this.countOption.xAxis.data = [];
          this.countOption.series[0].data = [];
          this.countOption.series[1].data = [];
          this.countOption.series[2].data = [];
          this.groupCount = response.data.data;
          for (let key in this.groupCount) {
            this.countOption.xAxis.data.push(this.groupCount[key].viewName);
            this.countOption.series[0].data.push(this.groupCount[key].totalNum);
            this.countOption.series[1].data.push(this.groupCount[key].pubNum);
            this.countOption.series[2].data.push(this.groupCount[key].totalHit);

          }
          this.echartsInit();
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    }

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {


  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //取得全部部门
    getCer().then(response => {
      if (response.data.success) {
        this.cerInfo = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
    getSys().then(response => {
      if (response.data.success) {
        this.sysInfo = response.data.data;
        let per = (this.sysInfo.jvmTotalMem - this.sysInfo.jvmFreeMem) / this.sysInfo.jvmTotalMem;
        per = (per * 100).toFixed(2);
        this.sysInfo.perNum = per - 0;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
    getDiskInfo().then(response => {
      if (response.data.success) {
        this.diskInfo = response.data.data;

      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
    getGroups().then(response => {
      if (response.data.success) {
        this.groups = response.data.data;

      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
    this.getCountData();
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
