import { httpForm, httpJson, httpFile } from '../../utils/request'
//定义一个Onduty接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/onduty/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/onduty/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/onduty/new', formData)
}
export const doPack = (formData) => {
    return httpForm.post('/onduty/inputExcel', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/onduty/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/onduty/delete', formData)
}
export const postFile = (fileData, config) => {
    return httpFile.post('/upload/file', fileData, config)
}
export const postImg = (fileData, config) => {
    return httpFile.post('/upload/img', fileData, config)
}
export const getOndutyEmployee = () => {
    return httpJson.get('/onduty/employee')
}

