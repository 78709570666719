<template>
  <div>
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <el-table ref="docWxTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" prop="userName" label="姓名" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.userName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="loginName" label="手机号" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.loginName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="loginPass" label="登录密码" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.loginPass)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="idNumber" label="身份证号" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.idNumber)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="departName" label="部门名称" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.departName)"></span>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>
    <!--编辑的表单-->
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import { getTableData, } from '@/api/wbkj/DocInfoWx.js'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
export default {
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '已推送微信文章'
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
    }
  },
  methods: {
    /*-------------------------------------------------特殊事件 -----------------------------------------------*/
    /*特殊事件-出错跟踪 */
    notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); },
    notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); },
    notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },

    /*特殊事件-下载文件 */
    downloadFile (downUrl, downName) { //下载文件
      let a = document.createElement('a')
      a.href = downUrl;
      a.download = downName;
      a.target = '_blank'//在新窗口打开
      a.click();
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {

        console.log(jsonData);
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },

  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.tableDateRefresh();
  }
}
</script>
