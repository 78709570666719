<template>
  <div>
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="18">
        <el-button-group>
          <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button>
          <el-button @click="formDataDelete" type="primary">删除</el-button>
          <el-button @click="inputExcel.visible=true" type="warning">批量导入</el-button>
        </el-button-group>
      </el-col>
      <el-col :span="6">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" @change="tableChangeSearchKey">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>

    <el-table ref="ondutyTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" prop="schedulDate" width="120" label="排班日期" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" prop="mainId" label="主班领导" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-popover placement="top-start" title="主班领导" trigger="hover">
            <p style="line-height: 12px;">姓名:{{scope.row.mainName}}</p>
            <p style="line-height: 12px;">职务:{{scope.row.mainDuty}}</p>
            <p style="line-height: 12px;">手机:{{scope.row.mainMobile}}</p>
            <p style="line-height: 12px;">值班电话:{{scope.row.mainTelPhone}}</p>
            <el-button slot="reference" size="small" type="success" plain>{{scope.row.mainName}}</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="leaderId" label="带班领导" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-popover placement="top-start" title="带班领导" trigger="hover">
            <p style="line-height: 12px;">姓名:{{scope.row.leaderName}}</p>
            <p style="line-height: 12px;">职务:{{scope.row.leaderDuty}} </p>
            <p style="line-height: 12px;">手机:{{scope.row.leaderMobile}}</p>
            <p style="line-height: 12px;">值班电话:{{scope.row.leaderTelPhone}}</p>
            <el-button slot="reference" size="small" type="success" plain>{{scope.row.leaderName}}</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="empId" label="值班人员" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-popover placement="top-start" title="值班人员" width="200" trigger="hover">
            <p style="line-height: 12px;">姓名:{{scope.row.empName}}</p>
            <p style="line-height: 12px;">职务:{{scope.row.empDuty}}</p>
            <p style="line-height: 12px;">手机:{{scope.row.empMobile}}</p>
            <p style="line-height: 12px;">值班电话:{{scope.row.empTelPhone}}</p>
            <el-button slot="reference" size="small" type="success" plain>{{scope.row.empName}}</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="backMan" label="备勤人员" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-popover placement="top-start" title="备勤人员" width="200" trigger="hover" v-for="emp in scope.row.backMan" :key="'emp'+emp.id"
            style="margin-left:5px;">
            <p style="line-height: 12px;">姓名:{{emp.name}}</p>
            <p style="line-height: 12px;">职务:{{emp.duty}}</p>
            <p style="line-height: 12px;">手机:{{emp.mobile}}</p>
            <p style="line-height: 12px;">值班电话:{{emp.telPhone}}</p>
            <el-button slot="reference" size="small" type="success" plain>{{emp.name}}</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="note" label="备注" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.note)"></span>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog class="customFileListCss" :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width"
      :top="formDialogParam.top" :close-on-click-modal='false'>
      <el-form ref="ondutyFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="排班日期" prop="schedulDate">
          <el-date-picker v-model="formData.schedulDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择排班日期"></el-date-picker>
        </el-form-item>

        <el-form-item label="主班领导" prop="mainId">
          <el-select v-model="formData.mainId" placeholder="请选择" @change="mainChange">
            <el-option v-for="emp in this.employees" :key="'main'+emp.id" :label="emp.name" :value="emp.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="带班领导" prop="leaderId">
          <el-select v-model="formData.leaderId" placeholder="请选择" @change="leaderChange">
            <el-option v-for="emp in this.employees" :key="'leader'+emp.id" :label="emp.name" :value="emp.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="值班人员" prop="empId">
          <el-select v-model="formData.empId" placeholder="请选择" @change="empChange">
            <el-option v-for="emp in this.employees" :key="'emp'+emp.id" :label="emp.name" :value="emp.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="note">
          <el-input v-model="formData.note" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="备勤人员" prop="backMan">
          <el-checkbox-group v-model="formData.backManId">
            <el-checkbox v-for="emp in this.employees" :label="emp.id" :key="emp.id">{{emp.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="30%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="schedulDate" label="选定的记日期">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="批量导入" :visible.sync="inputExcel.visible" width="30%" :close-on-click-modal='false'>
      <el-form :model="inputExcel" label-width="80px" size="small">
        <el-form-item label="批量文件" prop="re">
          <el-input v-model="inputExcel.excelUrl" class="input-with-select" placeholder="请按模板填写,上传(97-2003)xls文件">
            <template slot="append">
              <el-upload ref="excelUrlUpload" action="" :http-request="excelUrlUploadSubmit" :before-upload="excelUrlSubmitBefore" :on-error="notifyError"
                :show-file-list="false">
                <i class="el-icon-upload" style="font-size: 25px;color:#409EFF"></i>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="模板下载">
          <el-button type="success" @click="downloadFile('/fileUpload/model/onduty.xls','值班模板')">模板下载</el-button>
        </el-form-item>
        <el-descriptions title="注意事项" :column="1">
          <el-descriptions-item label="1">如果上传文件中日期已存在,会触发更新,即最后一次上传的时间为准</el-descriptions-item>
          <el-descriptions-item label="2">上传的模板的填写,正式数据必须从第三行开始</el-descriptions-item>
          <el-descriptions-item label="3">日期格式必须为XXXX年X月X日</el-descriptions-item>
          <el-descriptions-item label="4">如存在同名不同人的情况,请作好区分,否则会出现前端值班统计不准的情况</el-descriptions-item>
        </el-descriptions>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="inputExcel.visible = false">取 消</el-button>
        <el-button type="primary" @click="excelSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import { getTableData, doNew, doModify, doDelete, getOndutyEmployee, postFile, doPack } from '@/api/wbkj/Onduty'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  data () {
    return {
      inputExcel: {
        visible: false,
        excelUrl: '',

      },
      /*导航栏状态 */
      direcParam: {
        'directName': '排班/值班信息'
      },
      employees: [],
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '40%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        schedulDate: new Date(),
        mainId: '',
        mainName: '',
        mainDuty: '',
        mainMobile: '',
        mainTelPhone: '',
        leaderId: '',
        leaderName: '',
        leaderDuty: '',
        leaderMobile: '',
        leaderTelPhone: '',
        empId: '',
        empName: '',
        empDuty: '',
        empMobile: '',
        empTelPhone: '',
        backMan: [],
        backManId: [],
        note: '',
      },
      formDialogRules:
      {
        schedulDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        mainId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        mainName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        mainDuty: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        mainMobile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        mainTelPhone: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        leaderId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        leaderName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        leaderDuty: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        leaderMobile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        leaderTelPhone: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empDuty: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empMobile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        empTelPhone: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
      }
    }
  },
  methods: {
    /*-------------------------------------------------特殊事件 -----------------------------------------------*/
    /*特殊事件-出错跟踪 */
    notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); },
    notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); },
    notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },
    notifyError (error) { this.$notify.error({ title: '错误', message: error }); },
    /*特殊事件-下载文件 */
    downloadFile (downUrl, downName) { //下载文件
      let a = document.createElement('a')
      a.href = downUrl;
      a.download = downName;
      a.target = '_blank'//在新窗口打开
      a.click();
    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.$set(this.formData, "backManId", []); //增加一个属性进去
        for (let i = 0; i < this.formData.backMan.length; i++) {
          this.formData.backManId.push(this.formData.backMan[i].id);
        }
        this.$set(this.formData, "backMan", []); //清空原有选中,这一步很重要,
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    mainChange (value) {
      const main = this.getSelectEmployees(value)
      this.formData.mainId = main.id;
      this.formData.mainName = main.name;
      this.formData.mainDuty = main.duty;
      this.formData.mainMobile = main.mobile;
      this.formData.mainTelPhone = main.telphone;
    },
    leaderChange (value) {
      const leader = this.getSelectEmployees(value)
      this.formData.leaderId = leader.id;
      this.formData.leaderName = leader.name;
      this.formData.leaderDuty = leader.duty;
      this.formData.leaderMobile = leader.mobile;
      this.formData.leaderTelPhone = leader.telphone;
    },
    empChange (value) {
      const emp = this.getSelectEmployees(value)
      this.formData.empId = emp.id;
      this.formData.empName = emp.name;
      this.formData.empDuty = emp.duty;
      this.formData.empMobile = emp.mobile;
      this.formData.empTelPhone = emp.telphone;
    },
    getSelectEmployees (selectId) {
      for (let i = 0; i < this.employees.length; i++) {
        let item = this.employees[i];
        if (item.id == selectId) {
          const itemJson = JSON.stringify(item);
          return JSON.parse(itemJson);
        }
      }
    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    /*表单(form)-发布示例 */
    /*formDataPublic () {
        if (this.tableMultipleSelection.length < 1) {
            this.notifyInfo("您必须最少选择一条记录,才能发布");
        }
        else {
            this.formChooseDialogParam.title = "将选定的记录,发布/取消发布"
            this.formChooseDialogParam.formChooseAction = 'public';
            this.formChooseDialogParam.action1 = true;
            this.formChooseDialogParam.action1View = '取消发布';
            this.formChooseDialogParam.action2 = true;
            this.formChooseDialogParam.action2View = '发 布';
            this.formChooseDialogParam.visible = true;
        }
    },*/
    formDataValidate () {
      let result = true;
      this.$refs.ondutyFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      //console.log(this.formData.backManId);
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      for (let i = 0; i < this.formData.backManId.length; i++) {
        let selectId = this.formData.backManId[i];
        var index = this.employees.find(item => item.id === selectId)
        this.formData.backMan.push(index);
      }
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.backMan = JSON.stringify(this.formData.backMan);
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    excelSubmit () {
      this.inputExcel.visible = false;
      const data = new FormData()
      data.append('excelFileUrl', this.inputExcel.excelUrl);
      doPack(data).then(response => {
        if (response.data.success) {
          this.tableParam.key = '';
          this.tableParam.page = 1;
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })

    },


    /*上传excelUrl文件类型判断,默认doc,docx,pdf是都可以上传*/
    excelUrlSubmitBefore (file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'))
      if ('.xls'.indexOf(fileType) == -1) {  //允许的文件类型请自己修改,但请注意,是带.的
        this.notifyWarning('上传失败,请上传.xls文件')
        return false;
      }
      else {
        return true;
      }
    },
    excelUrlUploadSubmit (param) {
      const updateData = new FormData()
      updateData.append('file', param.file);
      updateData.append('childFile', 'onduty');
      postFile(updateData).then(response => {
        if (response.data.success) {
          const jsonData = response.data.data;
          this.inputExcel.excelUrl = jsonData.serverPath;
          param.onSuccess();  // 上传成功的图片会显示绿色的对勾
        }
        else {
          param.onError(response.data.msg);//会去调用出错的方法
        }
      }).catch(error => {
        param.onError(error); //会去调用出错的解决办法
      })
    },




    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.formData.backManId = {};
      this.$refs.ondutyTableRef.clearSelection(); //清除选中
      this.$refs.ondutyTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },

  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
    //取得全部部门
    getOndutyEmployee().then(response => {
      if (response.data.success) {
        this.employees = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
  }
}
</script>
