import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router' //必须那边声明 export default后才可以调用
import store from './store' //必须那边声明 export default后才可以调用
import * as echarts from 'echarts';


//import './api/mock'  //调用数据模拟,一般情况下是不需要的
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts //引入图表
/*设置全局Axios */
new Vue({
  router,//挂进router
  store,
  render: h => h(App),
}).$mount('#app')
