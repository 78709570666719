import { httpJson, httpFile } from '../../utils/request'
//定义一个Cer取得接口,数据表接口
export const getCer = () => {
    return httpJson.get('/cert/info')
}
export const getSys = () => {
    return httpJson.get('/sys/properties')
}
export const getDiskInfo = () => {
    return httpJson.get('/sys/diskInfo')
}
export const getGroupCount = (param) => {
    return httpJson.get('/sys/groupCount', { 'params': param })
}
export const getGroups = () => {
    return httpJson.get('/sys/groups')
}
export const postCerFile = (fileData) => {
    return httpFile.post('/upload/cerFile', fileData)
}




