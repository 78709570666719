export default {
  state: {
    isCollapse: false, //用于控制菜单展开还是收起,
    collapseIcon: 'el-icon-caret-left',//
    activeDoc: {
      id: 0,
      directName: ''
    }
  },
  mutations: {
    //定义一个修改菜单展开还是收起的方法
    cllapseMenu (state) {
      state.isCollapse = !state.isCollapse;
      if (state.isCollapse) {
        state.collapseIcon = 'el-icon-caret-left'

      }
      else {
        state.collapseIcon = 'el-icon-caret-right'
      }
    },
    setActiveDoc (state, data) {
      state.activeDoc.id = data.id;
      state.activeDoc.directName = data.directName;
    }
  }
}