import Vue from 'vue';
import Vuex from 'vuex';
import LeftMenuState from './LeftMenuState.js'
import loginState from './loginState.js'
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    loginState,
    LeftMenuState
  }
})