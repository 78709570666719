import { httpForm, httpJson, httpFile } from '../../utils/request'
//定义一个Departs接口,数据表接口
export const findDataBy = (tableParam) => {
    return httpJson.get('/departs/findDataBy', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/departs/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/departs/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/departs/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/departs/delete', formData)
}
export const doLock = (formData) => {
    return httpForm.post('/departs/lock', formData)
}
export const postFile = (fileData, config) => {
    return httpFile.post('/upload/file', fileData, config)
}
export const postImg = (fileData, config) => {
    return httpFile.post('/upload/img', fileData, config)
}


